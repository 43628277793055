:root {
  --inputs: #d9eaf7;
  --nottext: #2c3f03;
  --bgone: #07c3f7;
  --bgtwo: #f3f5e088;
}


.newnote-wraper {
  position: fixed;
  width: 300px;
  height: 330px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bgone);
  border-radius: 0.1rem;
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  border-radius: 0.3rem;
  box-shadow: -5px 10px 25px 5px rgba(0, 0, 0, 0.4);

}

.newnote-wraper .formWraper {
  position: relative;
}

.newnote-wraper .new-note {
  padding: 1.2rem 0.5rem;


}

.new-note input {
  background-color: var(--bgtwo);
  width: 100%;
  padding: 0.3rem 1rem;
  box-sizing: border-box;
  color: var(--nottext);
  border-radius: 0.2rem;
  font-size: 1.3rem;
  font-family: "Handlee", cursive;
  font-style: italic;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
}

::placeholder {
  color: #888;
  font-size: 1rem;
  text-align: center;
  font-style: italic;
}

.new-note textarea {
  margin-top: 1.5rem;
  background-color: var(--bgtwo);
  width: 100%;
  height: 180px;
  border-radius: 0.4rem;
  color: var(--nottext);
  padding: 1rem;
  box-sizing: border-box;
  resize: none;
  font-size: 1.3rem;
  font-family: "Handlee", cursive;
  font-style: italic;
  letter-spacing: 1.5px;

}

.new-note .post {
  background-color: #7c99b9;
  width: 100%;
  padding: 0.4rem 0;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
  color: #eee;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: italic;
  font-weight: 600;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
  cursor: pointer;
}

.new-note .post:hover {
  box-shadow: none;
}

.formWraper .closebtn {
  position: absolute;
  font-size: 1.1rem;
  top: -8px;
  right: -7px;
  background: transparent;
  color: #777;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.5s ease;

}

.formWraper .closebtn:hover {
  color: #fff;

}