.modals {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: rgb(189, 239, 90, 0.7);
  display: flex;
  justify-content: center;

}

.modals .modal-inner {
  width: 30rem;
  margin: 10rem auto;
  background-color: #f4f8ea;
  border-radius: 1rem;
  padding: 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.modals .modal-inner h2 {
  font-weight: 200;
  font-size: 2.2rem;
  margin: 0.8rem 0 1rem;
  color: orangered;
}

.modals .modal-inner hr {
  width: 50%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  margin-bottom: 1rem;

}

.modals .modal-inner h3 {
  margin: 1rem 0;
  color: rgb(133, 66, 4);
}

.modals .modal-inner .yesnobtn {
  margin: 1.5rem 0 0.8rem;
}

.modals .modal-inner .yesnobtn button {
  margin: 0 1rem;
  padding: 0.5rem 3rem;
  background-color: #1ea6f4;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  transition: all 0.5s ease;
  color: #eee;
}

.modals .modal-inner .yesnobtn button:hover {
  box-shadow: 2px 2px 4px orange;
  filter: brightness(0.8);
}










/* .modals .modal-inner @media screen and (max-width: 900px) {
  .modals {
    /* top: 8rem; */