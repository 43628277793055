@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');




* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
}

body {
  width: 100vw;
  min-width: 300px;
  min-height: 100vh;
  background-color: #c68c5388;
  display: flex;
  justify-content: center;
  overflow-x: hidden;

}

.app {
  width: 100vw;
  position: relative;
}