.board-wraper {
  max-width: 1180px;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1.5rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1.2rem;
}

button {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.main-title {
  font-size: 2.5rem;
  font-weight: 200;
  color: #f8f804;
  text-shadow: 2px 2px 6px #8c2702;
  letter-spacing: 2px;
}

.create {
  padding: 0.8rem 4rem;
  border-radius: 0.3rem;
  font-size: 1.1rem;
  background-color: lightgreen;
  position: relative;
}

.create .counts {
  position: absolute;
  left: 1.2rem;
  top: 0.4rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eee;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d5aa2;
  font-size: 0.9rem;

}

.create .arrows {
  position: absolute;
  right: 2rem;
  transition: all 0.5s ease;
}

.create:hover {
  filter: brightness(1.2);
}

.create:hover .arrows {
  right: 1rem;
}

.del-all {
  padding: 0.8rem 3rem;
  border-radius: 0.3rem;
  font-size: 1.1rem;
  background-color: rgb(243, 138, 10);
  position: relative;
  color: #fff;
  transition: all 0.5s ease;
}

.del-all span {
  font-size: 1.2rem;
  margin: 0 2px;
  color: #fafa1a;
  font-weight: 600;
}

.del-all:hover {
  filter: brightness(1.1);
}

.note-wraper {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0.5rem;
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  padding: 1rem;
  min-height: 80vh;
}

.note-wraper .notes {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 5px 10px 15px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
  position: relative;

}


.note-wraper .notes .title {
  font-size: 1.7rem;
  font-weight: 500;
  margin: 1.3rem 0 0.5rem;
  font-style: italic;
  transition: all 0.5s ease;
}

.note-wraper .notes hr {
  width: 55%;
  border-bottom: 1px solid #999;
  margin-bottom: 0.8rem;

}

.note-wraper .notes p {
  padding: 0 1.6rem;
  border-radius: 0.1rem;
  font-size: 1.3rem;
  font-family: "Handlee", cursive;
  font-style: italic;
  letter-spacing: 1.5px;
  transition: all 0.5s ease;
}

.note-wraper .notes:hover .title {
  margin-top: 1.2rem;
}

.note-wraper .notes:hover {
  box-shadow: 5px 10px 25px 2px rgba(0, 0, 0, 0.6);
}

.note-wraper .notes .deleter {
  position: absolute;
  padding: 0.4rem 0;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  color: #8c2702;
  border-radius: 0.1rem;
  font-style: italic;
  letter-spacing: 1px;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.8rem;
}

.note-wraper .notes .deleter:hover {
  filter: brightness(0.8);
}

.notes .boardpin {
  position: absolute;
  top: -13px;
  width: 1.4rem;

}

@media screen and (max-width: 1200) {
  .board-wraper {
    max-width: 1180px;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    margin: 6cqmin auto;
    overflow: hidden;
  }
}

@media screen and (max-width: 900px) {
  .navbar {
    height: 8rem;
    align-items: end;
    position: relative;
  }

  .main-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -5px;
    width: 300px;
    text-align: center;
  }

  .note-wraper {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .create {
    padding: 0.7rem 3.5rem;
    font-size: 1rem;
  }

  .del-all {
    padding: 0.7rem 2.5rem;
    font-size: 1rem;
  }

  .create .counts {
    left: 0.5rem;
    top: 0.35rem;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 0.8rem;
  }

  .create .arrows {
    right: 1.3rem;
  }

  .del-all span {
    font-size: 1rem;
    font-weight: 400;
  }

}

@media screen and (max-width: 500px) {

  .del-all {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }

  .create {
    padding: 0.7rem 2.1rem;
    font-size: 0.9rem;
  }

  .del-all span {
    font-size: 1rem;
    font-weight: 400;
  }

  .create .counts {
    left: 0.25rem;
    top: 0.45rem;
    width: 1.6rem;
    height: 1.6rem;
    font-size: 0.7rem;
  }

  .create .arrows {
    right: 0.8rem;
  }

  .create:hover .arrows {
    right: 0.6rem;
  }

}

@media screen and (max-width: 400px) {
  .del-all {
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
  }

  .create {
    padding: 0.5rem 2rem;
    font-size: 0.8rem;
  }

  .del-all span {
    font-size: 0.9rem;
    font-weight: 300;
  }

  .create .counts {
    left: 0.2rem;
    top: 0.2rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .create .arrows {
    right: 0.9rem;
    width: 0.5rem;
  }
}

@media screen and (max-width: 350px) {
  .del-all {
    font-size: 0.7rem;
  }

  .create {
    padding: 0.5rem 1.7rem;
    font-size: 0.7rem;
  }

  .del-all span {
    font-size: 0.7rem;
    font-weight: 200;
  }

  .create .counts {
    left: 0.2rem;
    top: 0.2rem;
    width: 1.3rem;
    height: 1.3rem;
  }

}